import { action, computed, makeObservable, observable } from 'mobx'
import { RootStore } from 'stores/index'
import { PriceManager } from './priceManager'
import { Currency, CurrencyMap, ProductPriceMap } from './types'
import { Product, ProductCore } from 'products/types'
import { CountryCode } from 'cart/types'
import { FinancingProvider } from 'components/_utils/utilityTypes'
import { EMAIL_SMS_DISCOUNT_AMOUNT } from 'stripe_lib/discounts'
import { getRegionCover, getRegionMattress, getRegionPod4, getRegionPod4Ultra, getRegionSleepEssentialsBundle } from './utils'

export class PriceStore {
	constructor(private readonly rootStore: RootStore, public readonly priceManager: PriceManager) {
		makeObservable(this)
	}

	@observable allPrices: ProductPriceMap = {}

	@action syncPrices() {
		this.rootStore.priceStore.priceManager.setAccessoriesDiscount(this.rootStore.cartStoreNew.hasPodOrCover && this.rootStore.promoStore.discountCode !== 'members')
		this.allPrices = this.priceManager.applyDiscountToAll(this.rootStore.promoStore.discountCode, this.rootStore.settingsStore.globalPromoCode, this.rootStore.settingsStore.currentRegion)

		this.coverPrices = this.getVariantPrices(getRegionCover(this.rootStore.settingsStore.currentRegion).id)
		this.pod4Prices = this.getVariantPrices(getRegionPod4(this.rootStore.settingsStore.currentRegion).id)
		this.pod4UltraPrices = this.getVariantPrices(getRegionPod4Ultra(this.rootStore.settingsStore.currentRegion).id)
		this.podPrices = this.getVariantPrices(getRegionMattress(this.rootStore.settingsStore.currentRegion)?.id)
		this.sleepEssentialsBundlePrices = this.getVariantPrices(getRegionSleepEssentialsBundle(this.rootStore.settingsStore.currentRegion)?.id)
		this.rootStore.shopStore.setPodShopData()
	}

	@observable coverPrices = this.getVariantPrices(getRegionCover(this.rootStore.settingsStore.currentRegion).id)
	@observable pod4Prices = this.getVariantPrices(getRegionPod4(this.rootStore.settingsStore.currentRegion).id)
	@observable pod4UltraPrices = this.getVariantPrices(getRegionPod4Ultra(this.rootStore.settingsStore.currentRegion).id)
	@observable podPrices = this.getVariantPrices(getRegionMattress(this.rootStore.settingsStore.currentRegion)?.id)
	@observable sleepEssentialsBundlePrices = this.getVariantPrices(getRegionSleepEssentialsBundle(this.rootStore.settingsStore.currentRegion)?.id)

	@computed get priceStoreDump() {
		return {
			podPrices: this.podPrices,
			coverPrices: this.coverPrices,
			pod4UltraPrices: this.pod4UltraPrices,
			pod4Prices: this.pod4Prices,
			sleepEssentialsBundlePrices: this.sleepEssentialsBundlePrices,
		}
	}

	getVariantPrices(variantId: number | string) {
		return this.priceManager.getVariantPrices(this.allPrices, variantId)
	}

	private getFormattedPrices(prices: CurrencyMap) {
		const currency = PriceManager.getPreferredCurrency(prices, this.currency)
		const price = Math.ceil(parseFloat(prices[currency].price!))
		return PriceManager.formatPriceToCurrencyNoDecimal(price, currency)
	}

	private getFormattedComparePrices(prices: CurrencyMap) {
		const currency = PriceManager.getPreferredCurrency(prices, this.currency)
		const price = Math.ceil(parseFloat(prices[currency].comparePrice!))
		return PriceManager.formatPriceToCurrencyNoDecimal(price, currency)
	}

	@computed get countryCodeFromCurrency(): CountryCode {
		switch (this.currency) {
			case 'USD':
				return 'US'
			case 'CAD':
				return 'CA'
			case 'GBP':
				return 'GB'
			case 'EUR':
				return 'DE'
			case 'AUD':
				return 'AU'
			case 'SEK':
				return 'SE'
			case 'DKK':
				return 'DK'
			case 'AED':
				return 'AE'
			default:
				return 'US'
		}
	}

	@computed get currency(): Currency {
		const region = this.rootStore.settingsStore.currentRegion
		return PriceManager.getRegionCurrency(region)
	}

	@action withPrices(product: ProductCore): Product {
		return this.priceManager.withPrices(product, this.allPrices, this.currency)
	}

	// cover prices

	@computed get coverPriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.coverPrices, this.currency)
		return parseFloat(this.coverPrices[currency].price!)
	}

	@computed get coverComparePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.coverPrices, this.currency)
		return parseFloat(this.coverPrices[currency].comparePrice!)
	}

	@computed get coverDiscountNumber() {
		const current = this.coverPriceNumber
		const compare = this.coverComparePriceNumber
		if (compare > current) {
			return compare - current
		}
		return 0
	}

	@computed get coverPriceFormatted() {
		return this.getFormattedPrices(this.coverPrices)
	}

	@computed get coverComparePriceFormatted() {
		return this.getFormattedComparePrices(this.coverPrices)
	}

	@computed get coverDiscountFormatted() {
		const currency = PriceManager.getPreferredCurrency(this.coverPrices, this.currency)
		const compare = parseFloat(this.coverPrices[currency].comparePrice!)
		const price = parseFloat(this.coverPrices[currency].price!)
		const finalPrice = compare - price
		return PriceManager.formatPriceToCurrencyNoDecimal(finalPrice, currency)
	}

	@computed get coverMonthlyPrice() {
		const price = parseFloat(this.coverPrices[this.currency].price!)
		return PriceManager.getAffirmFinancingAmount(price)
	}

	@computed get coverKlarnaMonthlyPrice() {
		const price = parseFloat(this.coverPrices[this.currency].price!)
		return PriceManager.getKlarnaFinancingAmount(price)
	}

	@computed get coverMonthlyPriceFormatted() {
		// ONLY CAD for now because financing not available outside US.
		const price = Math.ceil(this.coverMonthlyPrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get coverKlarnaMonthlyPriceFormatted() {
		const price = Math.ceil(this.coverKlarnaMonthlyPrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get coverMaxMonthsAtZeroInterest() {
		switch (this.currency) {
			case 'USD':
				return PriceManager.getAffirmMaxMonths(this.pod4PriceNumber * 100)
			case 'CAD':
				return PriceManager.getAffirmMaxMonths(this.pod4PriceNumber * 100)
			default:
				return NaN
		}
	}

	@computed get coverKlarnaMaxMonthsAtZeroInterest() {
		return PriceManager.getKlarnaMaxMonths(this.pod4PriceNumber * 100)
	}

	// pod 4 prices

	@computed get pod3PriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.coverPrices, this.currency)
		return parseFloat(this.coverPrices[currency].price!)
	}

	@computed get pod3ComparePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.coverPrices, this.currency)
		return parseFloat(this.coverPrices[currency].comparePrice!)
	}

	@computed get pod3PriceFormatted() {
		return this.getFormattedPrices(this.coverPrices)
	}

	@computed get pod3ComparePriceFormatted() {
		return this.getFormattedComparePrices(this.coverPrices)
	}

	@computed get pod3MonthlyPrice() {
		const price = parseFloat(this.coverPrices[this.currency].price!)
		return PriceManager.getAffirmFinancingAmount(price)
	}

	@computed get pod3MonthlyPriceFormatted() {
		const price = Math.ceil(this.pod3MonthlyPrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get pod3DiscountFormatted() {
		const currency = PriceManager.getPreferredCurrency(this.coverPrices, this.currency)
		const compare = parseFloat(this.coverPrices[currency].comparePrice!)
		const price = parseFloat(this.coverPrices[currency].price!)
		const finalPrice = compare - price
		return PriceManager.formatPriceToCurrencyNoDecimal(finalPrice, currency)
	}

	@computed get pod4PriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.pod4Prices, this.currency)
		return parseFloat(this.pod4Prices[currency].price!)
	}

	@computed get pod4ComparePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.pod4Prices, this.currency)
		return parseFloat(this.pod4Prices[currency].comparePrice!)
	}

	@computed get pod4PriceFormatted() {
		return this.getFormattedPrices(this.pod4Prices)
	}

	@computed get pod4ComparePriceFormatted() {
		return this.getFormattedComparePrices(this.pod4Prices)
	}

	@computed get pod4MonthlyPrice() {
		const price = parseFloat(this.pod4Prices[this.currency].price!)
		return PriceManager.getAffirmFinancingAmount(price)
	}

	@computed get pod4MonthlyPriceFormatted() {
		const price = Math.ceil(this.pod4MonthlyPrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get pod4DiscountNumber() {
		const currency = PriceManager.getPreferredCurrency(this.pod4Prices, this.currency)
		const compare = parseFloat(this.pod4Prices[currency].comparePrice!)
		const price = parseFloat(this.pod4Prices[currency].price!)
		const finalPrice = compare - price
		return finalPrice
	}

	@computed get pod4DiscountFormatted() {
		const currency = PriceManager.getPreferredCurrency(this.pod4Prices, this.currency)
		const finalPrice = this.pod4DiscountNumber
		return PriceManager.formatPriceToCurrencyNoDecimal(finalPrice, currency)
	}

	@computed get pod4UltraPriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.pod4UltraPrices, this.currency)
		return parseFloat(this.pod4UltraPrices[currency].price!)
	}

	@computed get pod4UltraComparePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.pod4UltraPrices, this.currency)
		return parseFloat(this.pod4UltraPrices[currency].comparePrice!)
	}

	@computed get pod4UltraPriceFormatted() {
		return this.getFormattedPrices(this.pod4UltraPrices)
	}

	@computed get pod4UltraComparePriceFormatted() {
		return this.getFormattedComparePrices(this.pod4UltraPrices)
	}

	@computed get pod4UltraMonthlyPrice() {
		const price = parseFloat(this.pod4UltraPrices[this.currency].price!)
		return PriceManager.getAffirmFinancingAmount(price)
	}

	@computed get pod4UltraMonthlyPriceFormatted() {
		const price = Math.ceil(this.pod4UltraMonthlyPrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get pod4UltraDiscountNumber() {
		const currency = PriceManager.getPreferredCurrency(this.pod4UltraPrices, this.currency)
		const compare = parseFloat(this.pod4UltraPrices[currency].comparePrice!)
		const price = parseFloat(this.pod4UltraPrices[currency].price!)
		const finalPrice = compare - price
		return finalPrice
	}

	@computed get pod4UltraDiscountFormatted() {
		const currency = PriceManager.getPreferredCurrency(this.pod4UltraPrices, this.currency)
		const discount = this.pod4UltraDiscountNumber
		return PriceManager.formatPriceToCurrencyNoDecimal(discount, currency)
	}

	// mattress prices

	@computed get podMonthlyPrice() {
		const price = parseFloat(this.podPrices[this.currency].price!)
		return PriceManager.getAffirmFinancingAmount(price)
	}

	@computed get podMonthlyComparePrice() {
		const price = parseFloat(this.podPrices['USD'].price!)
		return PriceManager.getAffirmFinancingAmount(price)
	}

	@computed get podMonthlyPriceFormatted() {
		const price = Math.ceil(this.podMonthlyPrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get podMonthlyComparePriceFormatted() {
		const price = Math.ceil(this.podMonthlyComparePrice)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, 'USD')
	}

	@computed get podPriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.podPrices, this.currency)
		return parseFloat(this.podPrices[currency].price!)
	}

	@computed get podComparePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.podPrices, this.currency)
		return parseFloat(this.podPrices[currency].comparePrice!)
	}

	@computed get podDiscountNumber() {
		const current = this.podPriceNumber
		const compare = this.podComparePriceNumber
		if (compare > current) {
			return compare - current
		}
		return 0
	}

	@computed get podPriceFormatted() {
		return this.getFormattedPrices(this.podPrices)
	}

	@computed get podComparePriceFormatted() {
		return this.getFormattedComparePrices(this.podPrices)
	}

	@computed get podDiscountFormatted() {
		const currency = PriceManager.getPreferredCurrency(this.podPrices, this.currency)
		const _compare = parseFloat(this.podPrices[currency].comparePrice!)
		const _price = parseFloat(this.podPrices[currency].price!)
		const price = Math.floor(_compare - _price)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, currency)
	}

	@computed get podMaxMonthsAtZeroInterest() {
		switch (this.currency) {
			case 'USD':
				return PriceManager.getAffirmMaxMonths(this.podPriceNumber * 100)
			case 'CAD':
				return PriceManager.getAffirmMaxMonths(this.podPriceNumber * 100)
			default:
				return NaN
		}
	}

	// bundle prices

	@computed get sleepEssentialsBundlePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.sleepEssentialsBundlePrices, this.currency)
		return parseFloat(this.sleepEssentialsBundlePrices[currency].price!)
	}

	@computed get sleepEssentialsBundleComparePriceNumber() {
		const currency = PriceManager.getPreferredCurrency(this.sleepEssentialsBundlePrices, this.currency)
		return parseFloat(this.sleepEssentialsBundlePrices[currency].comparePrice!)
	}

	@computed get sleepEssentialsBundleDiscountNumber() {
		const current = this.sleepEssentialsBundlePriceNumber
		const compare = this.sleepEssentialsBundleComparePriceNumber
		if (compare > current) {
			return compare - current
		}
		return 0
	}

	// total product prices = cover + bundle

	@computed get discountFormatted() {
		const region = this.rootStore.settingsStore.currentRegion
		if (region === '' || region === 'us' || region === 'ca') {
			return this.totalProductDiscountNumberFormatted
		}
		return this.pod4DiscountFormatted
	}

	@computed get totalProductDiscountNumber() {
		return this.pod4UltraDiscountNumber
	}

	@computed get allProductsDiscountNumber() {
		const currentDiscountCode = this.rootStore.promoStore.discountCode?.toLowerCase()
		const isMember = currentDiscountCode === 'members'
		const isReferral = currentDiscountCode === 'reffrb'
		if (isMember || isReferral) {
			return this.pod4UltraDiscountNumber
		}

		return this.pod4UltraDiscountNumber + this.sleepEssentialsBundleDiscountNumber + this.podDiscountNumber
	}

	@computed get getUpToDiscountNumber() {
		const path = typeof window !== 'undefined' ? window.location.pathname.toLowerCase() : ''
		const redGerard = path === '/red/' || path === '/red'
		const strava = path.includes('/strava')
		const efprocycling = path.includes('/efprocycling')

		const settingsStore = this.rootStore.settingsStore
		const { settings } = settingsStore

		const isVanity = !!(settings && settings.emailCapSettings) || redGerard || strava || efprocycling
		const amount = PriceManager.convertToCurrency(EMAIL_SMS_DISCOUNT_AMOUNT, PriceManager.getRegionCurrency(settingsStore.currentRegion))

		return isVanity ? this.allProductsDiscountNumber : amount + this.allProductsDiscountNumber
	}

	@computed get allProductsDiscountNumberFormatted() {
		const price = Math.ceil(this.allProductsDiscountNumber)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get totalProductDiscountNumberFormatted() {
		const price = Math.ceil(this.totalProductDiscountNumber)
		return PriceManager.formatPriceToCurrencyNoDecimal(price, this.currency)
	}

	@computed get financingProvider(): FinancingProvider {
		if (this.currency === 'USD' || this.currency === 'CAD') {
			return 'affirm'
		} else if (this.currency === 'GBP' || this.currency === 'EUR') {
			return 'klarna'
		} else {
			return 'none'
		}
	}
}
