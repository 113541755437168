import styles from './BenefitsContainer.module.scss'
import { Icon } from 'components/Icon'
import { Colors } from 'components/WebEv/Colors/colors'
import * as React from 'react'
import { FC } from 'react'
import { getBenefits } from 'components/WebEv/Shop/utils/productBenefits'

interface BenefitsProps {
	warrantyAmount: number
	shippingTimeline: string
	deliveryTimelines?: { bottomRange: number; topRange: number }
	startDate?: Date
}

export const BenefitsContainer: FC<BenefitsProps> = (props) => {
	const { deliveryTimelines, startDate } = props
	const benefits = getBenefits(props.warrantyAmount)

	return (
		<div className={styles.benefits}>
			{deliveryTimesAreValid(deliveryTimelines) ? <h3 dangerouslySetInnerHTML={{ __html: formatDeliveryTimeline(deliveryTimelines, startDate) }}></h3> : <h3>{props.shippingTimeline}</h3>}
			<ul className={styles.benefits_list}>
				{benefits.map((benefit, index) => (
					<li key={index}>
						<Icon
							icon={benefit.icon}
							color={Colors.Neutral.black.hex}
							className={styles.benefit_icon}
						/>
						<p>{benefit.description}</p>
					</li>
				))}
			</ul>
		</div>
	)
}

const deliveryTimesAreValid = (timelines: { bottomRange: number; topRange: number }) => {
	if (!timelines) return false
	const { bottomRange, topRange } = timelines
	return bottomRange > 0 && topRange > 0
}

const addDaysToStartDate = (days: number, startDate: Date) => {
	// Copy startDate so we don't modify the original
	const startDateCopy = new Date(startDate)
	return new Date(startDateCopy.setDate(startDateCopy.getDate() + days))
}

const formatDeliveryTimeline = (timelines: { bottomRange: number; topRange: number }, startDate = new Date()) => {
	const { bottomRange, topRange } = timelines
	const bottomDate = addDaysToStartDate(bottomRange, startDate)
	const topDate = addDaysToStartDate(topRange, startDate)

	const bottomDateString = bottomDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
	const topDateString = topDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })

	return `Estimated delivery ${bottomDateString} - ${topDateString}${
		bottomDate < new Date('2024-12-25') && topDate > new Date('2024-12-25') ? '.<br/><span>Not guaranteed to arrive by Christmas.</span>' : ''
	}`
}
