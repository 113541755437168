import moment from 'moment'
import { useRouter } from 'next/router'
import { Currency } from 'prices/types'
import { useEffect, useState } from 'react'
import Stripe from 'stripe'
import { STRIPE_PRODUCTS } from 'stripe_lib/products'
import { getRandomString } from './random'
import { SubscriptionCapability, SubscriptionData, SubscriptionFeature, SubscriptionResponse, SubscriptionType } from 'components/_utils/subscriptionUtils'
import { IconMap } from 'components/_const_assets/icons'
import { jwtDecode } from 'jwt-decode'
import { StripeManager } from 'stripe_lib'
import { datadogReportError } from 'events/datadog'

export const RECHARGE_API_TOKEN = 'sk_2x2_ee2962c531da2e4a828e952e245ed3991910aa635d991da3618f9f6ff4238069'
export const RECHARGE_API_URL = 'https://api.rechargeapps.com'

export enum SubscriptionTier {
	Pro = 'pro',
	Plus = 'plus',
	Grandfathered = 'grandfathered',
}

export enum SubscriptionProvider {
	Stripe = 'stripe',
	Recharge = 'recharge',
	Unknown = 'unknown',
}

type SubscriptionTierType = {
	[key in SubscriptionTier]: string
}

type SubscriptionRechargeProductId = {
	[key in SubscriptionTier]: number
}

type SubscriptionPriceTierType = {
	[x: string]: SubscriptionTier
}

export const SubscriptionTierPrice: SubscriptionTierType = {
	[SubscriptionTier.Pro]: STRIPE_PRODUCTS.autopilot_standard__yearly,
	[SubscriptionTier.Plus]: STRIPE_PRODUCTS.autopilot_enhanced__yearly,
	[SubscriptionTier.Grandfathered]: null,
}

export const subscriptionTypeToStripePrice = (subscriptionType: SubscriptionType) => {
	switch (subscriptionType) {
		case SubscriptionType.OPTIONAL_ERA_STANDARD:
			return STRIPE_PRODUCTS['8plus_standard__yearly']
		case SubscriptionType.OPTIONAL_ERA_PRO:
			return STRIPE_PRODUCTS['8plus_pro__yearly']
		case SubscriptionType.MANDATORY_ERA_STANDARD:
			return STRIPE_PRODUCTS.autopilot_standard__yearly
		case SubscriptionType.MANDATORY_ERA_ENHANCED:
			return STRIPE_PRODUCTS.autopilot_enhanced__yearly
		default:
			return null
	}
}

export const SubscriptionPriceTier: SubscriptionPriceTierType = {
	autopilot_standard__yearly: SubscriptionTier.Pro,
	autopilot_enhanced__yearly: SubscriptionTier.Plus,
}

export const SubscriptionTierShopifyRechargeId: SubscriptionRechargeProductId = {
	[SubscriptionTier.Pro]: 40218251133016, // Autopilot (Standard) variant ID in Shopify
	[SubscriptionTier.Plus]: 40218251165784, // Autopilot (Enhanced) variant ID in Shopify
	[SubscriptionTier.Grandfathered]: null, // Autopilot (Enhanced) variant ID in Shopify
}

export type SubscriptionStatus = Stripe.Subscription.Status | 'expired'

export enum SubscriptionPauseState {
	OneMonth = 1,
	ThreeMonths = 3,
	SixMonths = 6,
	Unpaused = 0,
}

export interface IRechargeCustomer {
	id: string
	first_name: string
	last_name: string
	email: string
	external_customer_id?: any
}

export interface IRechargePaymentMethod {
	id: number
	default: boolean
	payment_type: string
	payment_details: {
		brand: string
		exp_month: number
		exp_year: number
		last4: number
	}
}

export interface IRechargeSubscription {
	id: number
	customer_id: number
	status: string
	created_at: string
	cancelled_at: string
	cancellation_reason: string
	next_charge_scheduled_at: string
	product_title: string
	price: number
	properties: {
		name: string
		value: string
	}[]
}

export interface IRechargeCharge {
	id: number
	status: string
	type: string
	total_price: string
	created_at: string
	scheduled_at: string
	processed_at: string
	currency: Currency
}

export interface IPaymentMethod {
	id: string
	brand: string
	last4: string
	exp_month: number
	exp_year: number
	brand_logo: string
	isDefault: boolean
	onlyCard?: boolean
}

export interface ICustomerResponse {
	success: boolean
	error?: any
	payment_methods?: IPaymentMethod[]
	default_payment_method?: string
}

export type CardBrand = 'amex' | 'diners' | 'discover' | 'eftpos_au' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown' | 'shop_pay' | 'stripe link'

export interface SubscriptionDataWithType extends SubscriptionData {
	subscriptionType: SubscriptionType
	currency: Currency | null
	stripePaymentMethod?: string
	capabilities: SubscriptionCapability[]
}

export const getCardBrandLogo = (card_brand: string) => {
	const brand = card_brand?.toLowerCase() as CardBrand
	if (brand === 'amex') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/amex.png'
	if (brand === 'diners') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/diners.png'
	if (brand === 'discover') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/discover.png'
	if (brand === 'eftpos_au') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/etfpos_au.png'
	if (brand === 'jcb') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/jcb.png'
	if (brand === 'mastercard') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/mastercard.png'
	if (brand === 'unionpay') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/unionpay.png'
	if (brand === 'visa') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/card-logos/visa.png'
	if (brand === 'shop_pay') return 'https://vectorseek.com/wp-content/uploads/2023/09/Shop-Pay-Logo-Vector.svg-.png'
	if (brand === 'stripe link') return 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/link-by-stripe.jpg'
	return IconMap.credit_card
}

export const mapPlanFeatureToIcon = (feature: SubscriptionFeature) => {
	if (feature === SubscriptionFeature.TEMPERATURE_DIAL) return IconMap.temperature
	if (feature === SubscriptionFeature.TEMPERATURE_SCHEDULE) return IconMap.temperature_schedule
	if (feature === SubscriptionFeature.AUTOMATIC_TEMPERATURE) return IconMap.temperature
	if (feature === SubscriptionFeature.VIBRATION_AND_THERMAL_ALARM) return IconMap.vibrate
	if (feature === SubscriptionFeature.SLEEP_AND_HEALTH_REPORTS) return IconMap.heart_report
	if (feature === SubscriptionFeature.SLEEP_AND_HEALTH_INSIGHTS) return IconMap.bar_chart
	if (feature === SubscriptionFeature.SNORING_DETECTION) return IconMap.snoring_detection
	if (feature === SubscriptionFeature.SNORING_MITIGATION) return IconMap.snoring_mitigation
	if (feature === SubscriptionFeature.TWO_YEAR_WARRANTY) return IconMap.warranty
	if (feature === SubscriptionFeature.FIVE_YEAR_WARRANTY) return IconMap.shield_check
	return IconMap.home
}

export const getSubscriptionTypeNickname = (type: SubscriptionType) => {
	if (type === SubscriptionType.NO_SUBSCRIPTION) return 'No Plan'
	if (type === SubscriptionType.TEMPORARY) return 'Temporary Trial Plan'
	if (type === SubscriptionType.OPTIONAL_ERA_CHURNED) return 'No Plan'
	if (type === SubscriptionType.PRE_SUB_ERA_GRANDFATHERED) return 'Legacy Plan'
	if (type === SubscriptionType.OPTIONAL_ERA_GRANDFATHERED) return 'Legacy Plan'
	if (type === SubscriptionType.OPTIONAL_ERA_STANDARD) return 'Standard Plan'
	if (type === SubscriptionType.OPTIONAL_ERA_PRO) return 'Pro Plan'
	if (type === SubscriptionType.MANDATORY_ERA_STANDARD) return 'Standard Autopilot Plan'
	if (type === SubscriptionType.MANDATORY_ERA_ENHANCED) return 'Enhanced Autopilot Plan'
	return 'No Plan'
}

export const useInAppSubscriptionAuth = () => {
	const router = useRouter()
	const [subscription, setSubscription] = useState<SubscriptionDataWithType | null>(null)

	useEffect(() => {
		const subscriptionJSON = sessionStorage.getItem('subscription')
		if (subscriptionJSON) {
			try {
				const parse = JSON.parse(subscriptionJSON)
				setSubscription(parse)
			} catch (error) {
				datadogReportError(error, 'In-app membership Subscription Auth')
				setSubscription(null)
			}
		} else {
			if (!sessionStorage.getItem('auth_redirect')) sessionStorage.setItem('auth_redirect', window.location.pathname + window.location.search)
			router.push('/membership-details/')
		}
	}, [router])

	return subscription
}

export const subscriptionStatus = (subscription: SubscriptionData) => {
	const hasActiveSubscription =
		subscription && subscription.status === 'active' && subscription.subscriptionFrequency !== 'lifetime' && (subscription.willRenew || subscription.cancellationReason === 'temporary')
	const hasExpiringSubscription =
		subscription && subscription.status === 'active' && subscription.subscriptionFrequency !== 'lifetime' && !subscription.willRenew && subscription.cancellationReason !== 'temporary'
	const hasExpiredSubscription = subscription && subscription.status === 'canceled' && subscription.subscriptionFrequency !== 'lifetime' && !subscription.willRenew

	return {
		hasActiveSubscription,
		hasExpiringSubscription,
		hasExpiredSubscription,
	}
}

export const canChangePlan = (subscriptionType: SubscriptionType) => {
	return (
		subscriptionType === SubscriptionType.PRE_SUB_ERA_GRANDFATHERED ||
		subscriptionType === SubscriptionType.OPTIONAL_ERA_CHURNED ||
		subscriptionType === SubscriptionType.OPTIONAL_ERA_GRANDFATHERED ||
		subscriptionType === SubscriptionType.NO_SUBSCRIPTION ||
		subscriptionType === SubscriptionType.MANDATORY_ERA_ENHANCED
	)
}

type SubscriptionCurrencyPrice = {
	[key in Currency]: number
}

interface ISubscriptionPricing {
	[SubscriptionTier.Pro]: SubscriptionCurrencyPrice
	[SubscriptionTier.Plus]: SubscriptionCurrencyPrice
}

export const PLAN_PRICING: ISubscriptionPricing = {
	[SubscriptionTier.Pro]: {
		USD: 199,
		GBP: 199,
		EUR: 199,
		CAD: 299,
		AUD: 299,
		SEK: 2199,
		DKK: 1399,
		AED: 780,
	},
	[SubscriptionTier.Plus]: {
		USD: 299,
		GBP: 299,
		EUR: 299,
		CAD: 399,
		AUD: 499,
		SEK: 3299,
		DKK: 2099,
		AED: 1188,
	},
}

/**
 * Calculate the total unused amount of the current subscription
 * @param {string} renewal_date - next renewal date of the subscription in string format
 * @param {number} totalPrice - total price of the current subscription
 * @returns {number} - total unused amount of the current subscription, 0 if the subscription is already expired
 */
export const calculateProration = (renewal_date: string, totalPrice: number) => {
	const daysUntilRenewal = moment(renewal_date).diff(moment(), 'days', true)
	if (daysUntilRenewal <= 0) return 0
	return (daysUntilRenewal / 365) * totalPrice // should we use 365 or 366?
}

export const addDefaultPaymentMethod = async (customer: Stripe.Customer) => {
	try {
		if (customer && !customer.invoice_settings?.default_payment_method) {
			const payment_methods = await StripeManager.stripe.customers.listPaymentMethods(customer.id)
			const cardPaymentMethod = payment_methods.data.find((it) => it.card)
			if (cardPaymentMethod) {
				await StripeManager.stripe.customers.update(customer.id, {
					invoice_settings: {
						default_payment_method: cardPaymentMethod.id,
					},
				})
			}
		}
	} catch (error) {
		datadogReportError(error, 'In-app membership Adding default payment method')
	}
}

export const createStripeCustomer = async (email: string, firstName: string, lastName: string, rechargeCustomer?: IRechargeCustomer): Promise<Stripe.Customer> => {
	return await StripeManager.stripe.customers.create({
		email: email,
		name: firstName + ' ' + lastName,
		description: 'Auto-created customer from in-app subscription management',
		metadata: rechargeCustomer
			? {
					recharge_customer_id: rechargeCustomer?.id.toString(),
					ecommerce_customer_id: rechargeCustomer?.external_customer_id?.ecommerce?.toString(),
			  }
			: {},
	})
}

export const findStripeCustomer = async (email: string) => {
	try {
		const customers = await StripeManager.stripe.customers.list({ email: email, limit: 10, expand: ['data.invoice_settings'] })
		return customers?.data?.find((c) => c.invoice_settings?.default_payment_method) ?? customers?.data?.[0]
	} catch (error) {
		datadogReportError(error, 'In-app membership Find stripe customer')
		return null
	}
}

export const getUpgradeInfoBackend = async (jwt: string): Promise<SubscriptionResponse> => {
	const userId = jwtDecode(jwt).sub
	const apiFetch = await fetch(`https://app-api.8slp.net/v1/users/${userId}/upgradeInfo/`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${jwt}`,
		},
	})
	const result = (await apiFetch.json()) as SubscriptionResponse
	return result
}

export const fetchRechargeCustomer = async (email: string): Promise<IRechargeCustomer> => {
	const response = await fetch(`${RECHARGE_API_URL}/customers?email=${encodeURIComponent(email)}`, {
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
	})
	const data = await response.json()
	return data.customers?.[0]
}

export const fetchRechargeCustomerPaymentMethods = async (customer_id: string): Promise<IRechargePaymentMethod[]> => {
	const response = await fetch(`${RECHARGE_API_URL}/payment_methods?customer_id=${customer_id}`, {
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
	})
	const data = await response.json()
	return data.payment_methods
}

export const fetchRechargeSubscriptions = async (customerId: string): Promise<IRechargeSubscription[]> => {
	const response = await fetch(`${RECHARGE_API_URL}/subscriptions?customer_id=${customerId}`, {
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
	})
	const data = await response.json()
	return data.subscriptions as IRechargeSubscription[]
}

export const fetchRechargeSubscription = async (subscriptionId: string): Promise<IRechargeSubscription> => {
	const response = await fetch(`${RECHARGE_API_URL}/subscriptions/${subscriptionId}`, {
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
	})
	const data = await response.json()
	return data.subscription as IRechargeSubscription
}

export const upgradeRechargeSubscription = async (subscriptionId: string, currency: Currency = 'USD'): Promise<IRechargeSubscription> => {
	const response = await fetch(`${RECHARGE_API_URL}/subscriptions/${subscriptionId}/?force_update=true`, {
		method: 'PUT',
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			status: 'ACTIVE',
			quantity: 1,
			product_title: 'Autopilot (Plus)',
			price: PLAN_PRICING[SubscriptionTier.Plus][currency].toFixed(2),
			external_variant_id: SubscriptionTierShopifyRechargeId[SubscriptionTier.Plus],
		}),
	})
	const data = await response.json()
	return data.subscription as IRechargeSubscription
}

export const downgradeRechargeSubscription = async (subscriptionId: string, currency: Currency = 'USD'): Promise<IRechargeSubscription> => {
	const subscription = await fetchRechargeSubscription(subscriptionId)
	const proration = subscription.next_charge_scheduled_at ? calculateProration(subscription.next_charge_scheduled_at, PLAN_PRICING[SubscriptionTier.Plus][currency]) : 0
	const priceWithProration = proration - PLAN_PRICING[SubscriptionTier.Pro][currency]

	const subscriptionUpdateResponse = await fetch(`${RECHARGE_API_URL}/subscriptions/${subscriptionId}/?force_update=true`, {
		method: 'PUT',
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			status: 'ACTIVE',
			quantity: 1,
			product_title: 'Autopilot (Pro)',
			price: PLAN_PRICING[SubscriptionTier.Pro][currency].toFixed(2),
			external_variant_id: SubscriptionTierShopifyRechargeId[SubscriptionTier.Pro],
		}),
	})
	const subscriptionResponseData = await subscriptionUpdateResponse.json()
	const updatedSubscription = subscriptionResponseData.subscription as IRechargeSubscription

	const subscriptionChargesResponse = await fetch(`${RECHARGE_API_URL}/charges/?customer_id=${updatedSubscription.customer_id}&status=queued&sort_by=id-desc`, {
		method: 'GET',
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
	})
	const subscriptionChargesResponseData = await subscriptionChargesResponse.json()
	const subscriptionCharges = subscriptionChargesResponseData.charges as IRechargeCharge[]

	if (subscriptionCharges.length > 0 && priceWithProration > 0) {
		const chargeId = subscriptionCharges[0].id
		const discountCreateResponse = await fetch(`${RECHARGE_API_URL}/discounts`, {
			method: 'POST',
			headers: {
				'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				code: getRandomString(10),
				discount_type: 'fixed_amount',
				value: priceWithProration,
				duration: 'usage_limit',
				duration_usage_limit: 1,
			}),
		})
		const discountCreateResponseData = await discountCreateResponse.json()
		if (discountCreateResponseData.discount?.id) {
			const updateChargeResponse = await fetch(`${RECHARGE_API_URL}/charges/${chargeId}/apply_discount`, {
				method: 'POST',
				headers: {
					'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					discount_id: discountCreateResponseData.discount.id,
				}),
			})
			const updateChargeResponseData = await updateChargeResponse.json()
		}
	}

	return updatedSubscription
}

export const cancelRechargeSubscription = async (subscriptionId: string, send_email = true): Promise<IRechargeSubscription> => {
	const response = await fetch(`${RECHARGE_API_URL}/subscriptions/${subscriptionId}/cancel`, {
		method: 'POST',
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			cancellation_reason: 'user_initiated',
			send_email: send_email,
		}),
	})
	const data = await response.json()
	return data.subscription as IRechargeSubscription
}

export const activateRechargeSubscription = async (subscriptionId: string): Promise<IRechargeSubscription> => {
	const response = await fetch(`${RECHARGE_API_URL}/subscriptions/${subscriptionId}/activate`, {
		method: 'POST',
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
	})
	const data = await response.json()
	return data.subscription as IRechargeSubscription
}

export const setRechargeSubscriptionPause = async (subscriptionId: string, value: SubscriptionPauseState): Promise<IRechargeSubscription> => {
	const response = await fetch(`${RECHARGE_API_URL}/subscriptions/${subscriptionId}`, {
		method: 'PUT',
		headers: {
			'X-Recharge-Access-Token': RECHARGE_API_TOKEN,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			properties: [
				{
					name: 'pause',
					value: value,
				},
				{
					name: 'paused_on',
					value: value !== SubscriptionPauseState.Unpaused ? moment().toISOString() : null,
				},
			],
		}),
	})
	const data = await response.json()
	return data.subscription as IRechargeSubscription
}
