import { RegionShort } from 'utils/internationalization'
import { trackClickSupport, trackFooterLink } from 'events/index'

export const headerToID = (header: string) => header.split(' ').join('-').toLowerCase()

export const getPhoneNumber = (region: RegionShort) => {
	switch (region) {
		case 'eu':
		case 'au':
		case 'se':
		case 'dk':
			return {
				link: 'tel:+34 5 18 90 04 68',
				string: '+34 5 18 90 04 68',
			}
		case 'ae':
			return {
				link: 'tel:+34 5 18 90 04 68',
				string: '+34 5 18 90 04 68',
			}
		case 'uk':
			return {
				link: 'tel:+443308186258',
				string: '+44 (0) 330 818 6258',
			}
	}

	return {
		link: 'tel:+18886994015',
		string: '+1 (888) 699-4015',
	}
}

export const getBlogItems = () => [
	{ text: 'Sleep', href: 'https://www.eightsleep.com/blog/sleep/', onClick: () => trackFooterLink('blog-sleep') },
	{ text: 'Fitness', href: 'https://www.eightsleep.com/blog/fitness/', onClick: () => trackFooterLink('blog-fitness') },
	{ text: 'Wellness', href: 'https://www.eightsleep.com/blog/wellness/', onClick: () => trackFooterLink('blog-wellness') },
	{ text: 'Clinical', href: 'https://www.eightsleep.com/blog/science/', onClick: () => trackFooterLink('blog-science') },
]

export const getCompanyItems = () => [
	{ text: 'Careers', href: 'https://jobs.ashbyhq.com/eightsleep', onClick: () => trackFooterLink('careers') },
	{ text: 'Press', href: '/press', onClick: () => trackFooterLink('press') },
	{ text: 'Athletes', href: '/athletes', onClick: () => trackFooterLink('athletes') },
	{ text: 'Our Research', href: '/science', onClick: () => trackFooterLink('science') },
	{ text: 'Sleep Accessories', href: '/accessories', onClick: () => trackFooterLink('accessories') },
]

export const getSupportItems = () => {
	const isClientSide = typeof window !== 'undefined'
	const isLoggedIn = isClientSide && (localStorage.getItem('tokenData') || localStorage.getItem('authToken'))

	const items = []

	if (!isLoggedIn) {
		items.push({ text: 'Member Login', href: '/login', onClick: () => trackClickSupport('Login') })
	}

	items.push(
		{ text: 'FAQ', href: 'https://help.eightsleep.com/', onClick: () => trackClickSupport('General') },
		{ text: 'Autopilot', href: '/autopilot', onClick: () => trackClickSupport('Membership') },
		{ text: 'Warranty', href: '/warranty/', onClick: () => trackFooterLink('warranty') },
		{ text: 'Return Policy', href: '/return-policy/', onClick: () => trackFooterLink('return policy') },
		{ text: 'Accessibility', href: '/accessibility/', onClick: () => trackFooterLink('accessibility') }
	)

	return items
}

export const getLegalItems = (region: RegionShort) => {
	if (region === '') {
		return [
			{ text: 'Financing Policy*', href: '/financing' },
			{ text: 'Privacy Policy', href: 'https://www.iubenda.com/privacy-policy/379226/full-legal' },
			{ text: 'Sale Terms and Conditions', href: '/terms-and-conditions' },
		]
	}

	return [
		{ text: 'Privacy Policy', href: 'https://www.iubenda.com/privacy-policy/379226/full-legal' },
		{ text: 'Sale Terms and Conditions', href: '/terms-and-conditions' },
	]
}
