import { observable, computed, action, makeObservable } from 'mobx'
import { default_settings } from 'config/default'
import { EMAIL_PROMO_QUERY, SMS_PROMO_QUERY, getUrlPromoSettings } from 'config/url_promos'
import { getActiveSitePromo } from 'config/site_promos'
import { RootStore } from 'stores'
import { VanityData, SiteSettings } from 'config/types'
import { createVanitySettings, fillMessagesWithPodPrice, fillMessagesWithCoverPrice, mergeVanityIntoSiteSettings, fillMessagesWithDiffPrice, fillMessagesWithBasePrice } from 'config/utils'
import { PriceManager } from 'prices'
import { EU_REGIONS, regionIsMetric, RegionShort } from 'utils/internationalization'
import { discountCodes } from 'promos'
import { PodCoverProducts, BundleProducts, TheBaseProducts, MattressProducts } from 'products/allProducts'
import { getUnitForRegion } from 'constants/units'
import cloneDeep from 'lodash.clonedeep'
import { getVanityData, setVanityData } from 'config/vanity'

declare global {
	interface WindowEventMap {
		coupon_received: CustomEvent
	}
}

export const getURLQueryPromo = () => {
	const email = localStorage.getItem('email')
	const phone = localStorage.getItem('phone')
	const levels_email = localStorage.getItem('levels_email')
	if (phone) {
		return SMS_PROMO_QUERY
	} else if (email) {
		return EMAIL_PROMO_QUERY
	} else if (levels_email) {
		return 'campaign=levels'
	}
	if (levels_email) {
		return 'campaign=levels'
	}
	return window.location.search
}

export default class SettingsStore {
	constructor(private readonly rootStore: RootStore) {
		makeObservable(this)
	}

	@observable currentRegion: RegionShort = ''
	@observable currentCountry: string
	@observable currentPage = '/'
	@observable currentQuery = ''
	@observable settings: SiteSettings = default_settings
	@observable windowHistoryLength = 0
	@observable windowLocationHref = ''
	@observable isPodProductPageFirstLoad = true
	@observable siteSaleIsActive = false
	@observable globalPromoCode = ''
	@observable navBarTransparency = true
	@observable isVanity = false
	@observable coverStickyHeight = 0
	@observable localizeReady = false
	@observable localizeLanguage = null

	@computed get settingsStoreDump() {
		return {
			currentRegion: this.currentRegion,
			currentCountry: this.currentCountry,
			currentPage: this.currentPage,
			currentQuery: this.currentQuery,
			settings: this.settings,
			windowHistoryLength: this.windowHistoryLength,
			windowLocationHref: this.windowLocationHref,
			isPodProductPageFirstLoad: this.isPodProductPageFirstLoad,
			siteSaleIsActive: this.siteSaleIsActive,
			globalPromoCode: this.globalPromoCode,
			navBarTransparency: this.navBarTransparency,
			isVanity: this.isVanity,
			coverStickyHeight: this.coverStickyHeight,
			localizeReady: this.localizeReady,
		}
	}

	@action setRegion = (region?: string) => {
		if (region == null) {
			return
		}

		let _region: RegionShort

		switch (region) {
			case 'eu':
			case 'de':
			case 'es':
			case 'it':
			case 'nl':
			case 'fr':
			case 'fi':
			case 'at':
				_region = 'eu'
				break
			case 'se':
				_region = 'se'
				break
			case 'dk':
				_region = 'dk'
				break
			case 'gb':
			case 'uk':
				_region = 'uk'
				break
			case 'ca':
				_region = 'ca'
				break
			case 'uae':
			case 'ae':
				_region = 'ae'
				break
			case 'au':
				_region = 'au'
				break
			default:
				_region = ''
		}

		this.currentCountry = region
		this.currentRegion = _region
		this.rootStore.productStore.refreshProducts()
	}

	@action setCurrentPage = (page: string) => {
		this.currentPage = page
	}

	@action setNavBarTransparency = (transparency: boolean) => {
		this.navBarTransparency = transparency
	}

	@action setCoverStickyHeight = (height: number) => {
		this.coverStickyHeight = height
	}

	@computed get isUS() {
		return this.currentRegion === ''
	}

	@computed get isCanada() {
		return this.currentRegion === 'ca'
	}

	@computed get isUK() {
		return this.currentRegion === 'uk'
	}

	/**
	 * @deprecated Currently includes regions like SE & DK, should be removed, use isEU instead
	 */
	@computed get isEULegacy() {
		return this.currentRegion === 'eu' || EU_REGIONS.includes(this.currentCountry)
	}

	@computed get isEU() {
		return this.currentRegion === 'eu'
	}

	@computed get isAU() {
		return this.currentRegion === 'au'
	}

	@computed get isSE() {
		return this.currentRegion === 'se'
	}

	@computed get isDK() {
		return this.currentRegion === 'dk'
	}

	@computed get isAE() {
		return this.currentRegion === 'ae'
	}

	@computed get isUAE() {
		return this.currentRegion === 'ae'
	}

	@computed get region() {
		if (this.isCanada) {
			return 'CA'
		}
		if (this.isUK) {
			return 'UK'
		}
		if (this.isEU) {
			return 'EU'
		}
		if (this.isAU) {
			return 'AU'
		}
		if (this.isSE) {
			return 'SE'
		}
		if (this.isDK) {
			return 'DK'
		}
		if (this.isUAE) {
			return 'AE'
		}
		return 'USA'
	}

	@computed get defaultProduct(): string {
		if (this.isEU) {
			return 'EU Large Double'
		}
		if (this.isUK) {
			return 'UK King'
		}
		return 'Queen'
	}

	@action setLocalizeReady = () => {
		this.localizeReady = true
		this.localizeLanguage = typeof window !== 'undefined' ? window.Localize?.getLanguage() ?? null : null
	}

	@computed get metricRegion() {
		return regionIsMetric(this.currentRegion)
	}

	@computed get saleEndDate() {
		let endDate = this.settings.endDate
		const dc = this.rootStore.promoStore.discountCode
		if (!endDate || dc === 'members') {
			endDate = ''
		}
		return endDate
	}

	/**
	 * @deprecated Only used in dead pages, should be removed
	 */
	@action convertUnit(text: string): string {
		const region = this.currentRegion
		return getUnitForRegion(text, region)
	}

	@action setWindowHistoryLength = (length: number) => {
		this.windowHistoryLength = length
	}

	@action setWindowLocationHref = (href: string) => {
		this.windowLocationHref = href
	}

	@action loadSettings = (vanity_data?: VanityData, return_settings?: boolean) => {
		// check query param sales
		// check vanity url sales
		// auto applied discount?
		const vanity = typeof window !== 'undefined' && !return_settings ? getVanityData() ?? vanity_data : vanity_data

		// Handle $50 increase from Klaviyo email
		// if (!!vanity && typeof window !== 'undefined') {
		// 	if ((window.location.search.toLowerCase().includes('klaviyo') || localStorage.getItem('email') || localStorage.getItem('phone')) && !vanity.promoCode?.includes('50')) {
		// 		vanity.promoCode += '50'
		// 	}
		// }

		if (!return_settings) {
			this.isVanity = !!vanity
			if (this.isVanity) {
				setVanityData(vanity.page)
			}
		}

		const defaultSettings = default_settings
		let promoBarCombined = cloneDeep(defaultSettings.promoBar)

		let siteSettings = defaultSettings
		const activeSiteSale = cloneDeep(getActiveSitePromo(this.currentRegion))

		const referral_session_code = typeof window !== 'undefined' ? sessionStorage.getItem('referral_session_code') : null
		if (typeof window !== 'undefined' && !referral_session_code) {
			this.removeCouponReceivedListener()
			this.addCouponReceivedListener()
		}

		const vanitySettings = return_settings ? vanity : createVanitySettings(vanity)
		if (vanitySettings?.promoBar) {
			const promoBarMessage = `BLACK FRIDAY: Up to %%% off with code <strong>${vanitySettings.promoCode.toUpperCase()}</strong>`
			vanitySettings.promoBar['*'].message = promoBarMessage
			vanitySettings.promoBar['/pod-cover'] = cloneDeep(vanitySettings.promoBar['*'])
			vanitySettings.promoBar['/product/pod-cover'] = cloneDeep(vanitySettings.promoBar['*'])
			vanitySettings.promoBar['/pod-prospecting'] = cloneDeep(vanitySettings.promoBar['*'])
			vanitySettings.promoBar['/notboring-prospecting'] = cloneDeep(vanitySettings.promoBar['*'])
		}

		const urlSettings = typeof window !== 'undefined' && !this.isVanity ? getUrlPromoSettings(referral_session_code ? '?referral=code' : getURLQueryPromo()) : null
		if (activeSiteSale) {
			this.siteSaleIsActive = true
			this.globalPromoCode = activeSiteSale.promoCode
			// merge promo bar obj together
			promoBarCombined = cloneDeep({ ...promoBarCombined, ...activeSiteSale.promoBar })
			siteSettings = { ...defaultSettings, ...activeSiteSale, promoCode: activeSiteSale.promoCode ?? siteSettings.promoCode }
			siteSettings.promoBar = promoBarCombined
		}

		/* 
			I THINK the siteSettings should be merged with the abTest settings here. 
			The current priority is this:
				default -> sitepromo -> vanity -> url (special email link)

			I think it should be 
			default -> sitepromo -> ABTESTS -> vanity -> url

			that way we don't override the vanity covers.

			So once this is all working if someone lands on the site and there is no AB test
			then they see the normal cover

			If someone lands on the site and they're in the variant they see the discounted cover (new product from ab_tests.ts)

			IIf someone lands on the site and they're in the variant, BUT they land on /tim then they see /tim special covers.
		*/

		// we are currently allowing discount stacking with our prediscounted product from the site sale and vanity codes
		// because of this, if a vanity is present we can merge on top of our current settings even if there is already an active sale
		if (vanitySettings && !urlSettings) {
			promoBarCombined = cloneDeep({ ...promoBarCombined, ...vanitySettings.promoBar })
			siteSettings = mergeVanityIntoSiteSettings(siteSettings, vanitySettings)
			siteSettings.promoBar = promoBarCombined
		}

		if (urlSettings) {
			if ((window.location.search.toLowerCase().includes('klaviyo') || localStorage.getItem('email') || localStorage.getItem('phone')) && urlSettings.promoCode === 'EF200') {
				urlSettings.promoCode += '50'
			}

			const currentPriority = siteSettings.promoBar?.priority ?? 0
			const urlSettingsPriority = urlSettings.promoBar?.priority ?? 0
			promoBarCombined = cloneDeep(urlSettingsPriority > currentPriority ? urlSettings.promoBar : { ...promoBarCombined, ...urlSettings.promoBar })
			siteSettings = { ...siteSettings, ...urlSettings, promoCode: urlSettings.promoCode ?? siteSettings.promoCode }
			siteSettings.promoBar = promoBarCombined
			siteSettings.promoCode = urlSettings.promoCode ?? siteSettings.promoCode
		}

		if (!this.isUS) {
			Object.keys(siteSettings.promoBar ?? {}).forEach((k) => {
				const key = siteSettings.promoBar[k]
				if (key && key.message) {
					key.message = key.message.replace('Bundle and save up to', 'Save up to')
				}
			})
		}

		const currency = PriceManager.getRegionCurrency(this.currentRegion)
		if (siteSettings.promoCode && discountCodes[siteSettings.promoCode] && currency) {
			let globalPodSale = 0
			let globalCoverSale = 0

			const sleepEssentialsBundleId = BundleProducts.SleepEssentialBundle.variants['queen'].id
			const pod4CoverUltraId = PodCoverProducts.Pod4Ultra.variants['queen'].id
			const mattressId = this.currentRegion === 'ca' ? MattressProducts.ThreeLayer.variants['caqueen'].id : MattressProducts.FiveLayerDiscounted.variants['queen'].id

			if (this.globalPromoCode !== '') {
				globalCoverSale = discountCodes[this.globalPromoCode][pod4CoverUltraId].USD as number
				globalPodSale = (discountCodes[this.globalPromoCode][sleepEssentialsBundleId].USD as number) ?? 0
			}

			const currentCoverSale = discountCodes[siteSettings.promoCode][pod4CoverUltraId].USD as number
			const currentPodSale = discountCodes[siteSettings.promoCode][sleepEssentialsBundleId].USD as number
			let podDiscount = (discountCodes[siteSettings.promoCode][sleepEssentialsBundleId][currency] ?? 0) + (discountCodes[siteSettings.promoCode][mattressId][currency] ?? 0)
			let coverDiscount = discountCodes[siteSettings.promoCode][pod4CoverUltraId][currency]
			const baseDiscount = discountCodes[siteSettings.promoCode][TheBaseProducts.TheBase.variants['queen'].id]

			if (globalPodSale + globalCoverSale > currentPodSale + currentCoverSale) {
				if (globalPodSale > currentPodSale) {
					podDiscount = discountCodes[this.globalPromoCode][sleepEssentialsBundleId][currency]
				}

				if (globalCoverSale > currentCoverSale) {
					coverDiscount = discountCodes[this.globalPromoCode][pod4CoverUltraId][currency]
				}
			}

			if (currency !== 'USD' && currency !== 'CAD') {
				podDiscount = coverDiscount
			} else {
				podDiscount += coverDiscount
			}
			if (podDiscount) {
				fillMessagesWithPodPrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal(podDiscount, currency))
			}
			if (baseDiscount) {
				fillMessagesWithBasePrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal(baseDiscount[currency], currency))
			}
			if (coverDiscount) {
				fillMessagesWithCoverPrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal(coverDiscount, currency))

				if ((currentCoverSale as number) > globalCoverSale) {
					fillMessagesWithDiffPrice(siteSettings, PriceManager.formatPriceToCurrencyNoDecimal((currentCoverSale as number) - globalCoverSale, currency))
				}
			}
		}

		if (referral_session_code && urlSettings) {
			siteSettings.promoBar = cloneDeep({ ...urlSettings.promoBar })
			Object.keys(siteSettings.promoBar ?? {}).forEach((k) => {
				if (k === 'priority') {
					return
				}
				const key = siteSettings.promoBar[k]
				if (key) {
					siteSettings.promoBar[k]!.message = key.message.replace('XYZ', referral_session_code)
				}
			})
		}

		if (return_settings) {
			return siteSettings
		}

		this.settings = siteSettings
		this.rootStore.promoStore.setPromoBar(this.currentPage)
		this.rootStore.promoStore.setPromo(siteSettings.promoCode)

		return null
	}

	handleCouponReceived = () => {
		this.loadSettings()
	}

	addCouponReceivedListener() {
		if (typeof window !== 'undefined') {
			window.addEventListener('coupon_received', this.handleCouponReceived, false)
		}
	}

	removeCouponReceivedListener() {
		if (typeof window !== 'undefined') {
			window.removeEventListener('coupon_received', this.handleCouponReceived)
		}
	}

	getLocalizeLanguage(): string {
		return this.currentRegion === 'eu' && typeof window !== 'undefined' ? window.Localize?.getLanguage() ?? 'en' : 'en'
	}
}
