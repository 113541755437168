import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { Toast } from 'components/Toast/Toast'
import styles from '../Toast.module.scss'
import { useEffect, useRef, useState } from 'react'

export const BFCMShopToast = observer(() => {
	const { shopStore, settingsStore, priceStore, promoStore } = useRootStore()
	const { settings } = settingsStore

	const isVanity = !!(settings && settings.emailCapSettings)

	const toastRef = useRef<HTMLDivElement>()
	const [maxHeight, setMaxHeight] = useState(0)

	useEffect(() => {
		if (toastRef.current) setMaxHeight(toastRef.current.clientHeight)

		const onResize = () => {
			if (toastRef.current) setMaxHeight(toastRef.current.clientHeight)
		}

		window.addEventListener('resize', onResize)
		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [shopStore.showShopBanner])

	useEffect(() => {
		const urlParams = 'utm_source=klaviyo'
		if (typeof window !== 'undefined' && window.location.href.toLowerCase().includes(urlParams)) {
			shopStore.setShowShopBanner(true)
		}
	}, [])

	const handleClose = () => {
		shopStore.setShowShopBanner(false)
	}

	const open = shopStore.showShopBanner

	if (priceStore.currency !== 'USD' && priceStore.currency !== 'CAD') {
		return null
	}

	const currentDiscountCode = promoStore.discountCode?.toLowerCase()
	const isMember = currentDiscountCode === 'members'
	if (isMember) {
		return null
	}

	return (
		<div
			className={styles.shop_toast_wrapper}
			style={{
				maxHeight: open ? `${maxHeight || 10000}px` : 0,
			}}
			data-open={open}
		>
			<Toast
				handleClose={handleClose}
				theme={'bfcm'}
				eyebrow={isVanity ? 'Black Friday Offer' : `Cyber Monday Extended Offer`}
				text={`Get up to ${priceStore.allProductsDiscountNumberFormatted} off when you bundle with your Pod purchase`}
				ref={toastRef}
			/>
		</div>
	)
})
